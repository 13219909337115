import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signinUser } from '../actions';
import HolyGrail from '../components/holygrail';
import { Button, Input, Form } from '../components/form';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
    };

    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.loginUser = this.loginUser.bind(this);
  }

  onPasswordChange(event) {
    event.preventDefault();
    this.setState({ password: event.target.value });
  }

  loginUser(event) {
    event.preventDefault();
    this.props.signinUser(this.state.password, this.props.history);
  }

  render() {
    return (
      <HolyGrail>
        <Form>
          <Input type="password" value={this.state.password} placeholder="Password..." onChange={this.onPasswordChange} />
          <Button type="button" onClick={this.loginUser}>
            Submit
          </Button>
        </Form>
      </HolyGrail>
    );
  }
}

export default withRouter(connect(null, { signinUser })(SignIn));
