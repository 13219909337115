import React from 'react';
import styled from 'styled-components';
import HolyGrail from './holygrail';

const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FallBack = (props) => {
  return (
    <HolyGrail>
      <Box>
        <span role="img" aria-label="Confused">URL not found 😕</span>
      </Box>
    </HolyGrail>
  );
};

export default FallBack;
