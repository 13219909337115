import styled, { css } from 'styled-components';

const Img = styled.img`
  max-height: 50vh;
  height: 50vh;
  outline: none;
  ${props => props.secondary && css`
    &:hover {
      cursor: crosshair;
    }
  `}
`;

export default Img;
