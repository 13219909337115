import axios from 'axios';

const ROOT_URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:9090/api'
  : 'https://rrod.herokuapp.com/api';

export const ActionTypes = {
  FETCH_POSTS: 'FETCH_POSTS',
  FETCH_POST: 'FETCH_POST',
  AUTH_USER: 'AUTH_USER',
  DEAUTH_USER: 'DEAUTH_USER',
  AUTH_ERROR: 'AUTH_ERROR',
};

export function signinUser(password, history) {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const user = { email: 'hi@rrod.me', password };
  return (dispatch) => {
    axios.post(`${ROOT_URL}/signin`, user, { headers }).then((response) => {
      dispatch({ type: ActionTypes.AUTH_USER });
      localStorage.setItem('token', response.data.token);
      history.push('/');
    }).catch((error) => {
      dispatch(authError(`Sign in failed: ${error.response.data}`));
    });
  };
}

export function signoutUser(history) {
  return (dispatch) => {
    localStorage.removeItem('token');
    dispatch({ type: ActionTypes.DEAUTH_USER });
    history.push('/');
  };
}

export function authError(error) {
  return {
    type: ActionTypes.AUTH_ERROR,
    message: error,
  };
}

export function fetchPosts() {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  return (dispatch) => {
    axios.get(`${ROOT_URL}/posts`, { headers }).then((response) => {
      dispatch({ type: ActionTypes.FETCH_POSTS, payload: response.data });
    }).catch((error) => {
      dispatch(authError(error.response.data));
    });
  };
}

export function fetchPost(slug) {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  return (dispatch) => {
    axios.get(`${ROOT_URL}/posts/${slug}`, { headers }).then((response) => {
      dispatch({ type: ActionTypes.FETCH_POST, payload: response.data });
    }).catch((error) => {
      dispatch(authError(error.response.data));
    });
  };
}
