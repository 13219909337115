import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';
import { ActionTypes } from './actions';
import Posts from './containers/posts';
import FallBack from './components/fallback';
import requireAuth from './containers/auth';
import Post from './containers/post';
import SignIn from './containers/signin';
import './style.scss';

export const store = createStore(reducers, {}, compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
));

const token = localStorage.getItem('token');
if (token) {
  store.dispatch({ type: ActionTypes.AUTH_USER });
}

const App = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={requireAuth(Posts)} />
        <Route path="/posts/:slug" component={requireAuth(Post)} />
        <Route path="/signin" component={SignIn} />
        <Route component={FallBack} />
      </Switch>
    </Router>
  );
};

ReactDOM.render(
  <Provider store={store}>
    {console.log('© Copyright Raul Rodriguez, 2019. All rights reserved.')}
    <App />
  </Provider>,
  document.getElementById('root'),
);
