import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPost } from '../actions';
import HolyGrail from '../components/holygrail';
import Img from '../components/img';
import Scroll from '../components/scroll';

class Post extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.fetchPost(this.props.match.params.slug);
  }

  renderImage() {
    return (
      this.props.selectedPost.image_urls.map((url) => {
        return (
          <Img src={url} alt={this.props.selectedPost.title} />
        );
      })
    );
  }

  render() {
    if (this.props.selectedPost.image_urls !== undefined) {
      return (
        <HolyGrail>
          <Scroll>
            {this.renderImage()}
          </Scroll>
        </HolyGrail>
      );
    } else {
      return (<HolyGrail />);
    }
  }
}

const mapStateToProps = state => ({
  selectedPost: state.posts.post,
});

export default withRouter(connect(mapStateToProps, { fetchPost })(Post));
