import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPosts } from '../actions';
import HolyGrail from '../components/holygrail';
import Img from '../components/img';
import Scroll from '../components/scroll';

class Posts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.goToPost = this.goToPost.bind(this);
  }

  componentWillMount() {
    this.props.fetchPosts();
  }

  goToPost(slug) {
    this.props.history.push(`/posts/${slug}`);
  }

  renderImages() {
    return (
      this.props.allPosts.map((post) => {
        return (
          <Img secondary role="button" tabIndex={0} onClick={() => { this.goToPost(post.slug); }} src={post.image_urls[0]} alt={post.title} />
        );
      })
    );
  }

  render() {
    return (
      <HolyGrail>
        <Scroll>
          {this.renderImages()}
        </Scroll>
      </HolyGrail>
    );
  }
}

const mapStateToProps = state => ({
  allPosts: state.posts.all,
  selectedPost: state.posts.post,
});

export default withRouter(connect(mapStateToProps, { fetchPosts })(Posts));
