import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  background: transparent;
  border: none;
  outline: 0;
  font: inherit;
  padding: 1px 0;
`;

export const Button = styled.button`
  border: none;
  padding: none;
  color: black;
  font-weight: inherit;
  font-size: inherit;
  background: none;
  outline: none;
  &:hover {
    cursor: crosshair;
    font-style: italic;
  }
`;
