import React from 'react';
import styled from 'styled-components';
import Marquee from 'react-smooth-marquee';

const ImgWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const Scroll = (props) => {
  return (
    <Marquee>
      <ImgWrap>
        {props.children}
      </ImgWrap>
    </Marquee>
  );
};

export default Scroll;
