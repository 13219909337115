import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { NavLink } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0;
    font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)));
    font-family: 'Graphik';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  a {
    text-decoration: none;
    color: black;
  }
  a:hover {
    cursor: crosshair;
    font-style: italic;
  }
  .Marquee {
    overflow-x: hidden;
  }
  .MarqueeContent {
    display: inline-block;
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  flex: 0 0 auto;
  padding: 2% 3%;
`;

const Content = styled.div`
  flex: 1 1 auto;
  position: relative;
  padding: 2% 3%;
  overflow-y: auto;
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;
`;

const Footer = styled.div`
  flex: 0 0 auto;
  display: flex;
  width: calc(100vw - 6%);
  justify-content: space-between;
  padding: 2% 3%;
`;

const Socials = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-end;
`;

const HolyGrail = (props) => {
  return (
    <Container>
      <GlobalStyle />
      <Header>
        <NavLink to="/" exact>Raul Rodriguez</NavLink>
      </Header>
      <Content>
        {props.children}
      </Content>
      <Footer>
        <div>
          <a href="mailto:hi@rrod.me">hi@rrod.me</a>
          <br />
          <a href="tel:+6464501049‬">+1 (646) 450-1049</a>
          <br />
          <br />
          {'Based in New York, NY'}
        </div>
        <Socials>
          <div />
          <div>
            <a href="https://github.com/rah-ool">GitHub</a>
            {', '}
            <a href="https://linkedin.com/in/rrod">LinkedIn</a>
          </div>
        </Socials>
      </Footer>
    </Container>
  );
};

export default HolyGrail;
